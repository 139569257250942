
import { computed, defineComponent, onMounted, ref } from "vue";
import { StepperComponent } from "@/assets/ts/components/_StepperComponent";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useForm } from "vee-validate";
import { Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";

import {getcountries, getcities, getjobclassifications, getoviage}  from "@/api/common";
import {  save_sealproform }  from "@/api/sealproencode";

import { validateMultiple, saveRegMultiple } from "@/api/register"


import { getCurrentUser } from "@/utils/index"
import ConfirmRegisterSealProEncode from "@/views/modals/ConfirmRegisterSealProEncode.vue";
import { getOVICoverageInfo, getRegcodesCoverages, getImmediateCoverages, getrenewalavailablecoverages } from "@/api/ovi_api";

import {hasOVIPackage}  from "@/api/network.api";

interface Step1 { 
  insured_firstName: string;
  insured_middleName: string;
  insured_lastName: string;
  insured_birthday: string; 
}

interface Step2 { 
  

  insured_mobileNumber: string;
  insured_email: string;  
  address_blockAndStreet: string;
  address_nameOfBuilding: string;
  address_unitNumber:  string;
  address_city:  string;
  address_postalCode:  string;
  address_country:  string;
  address_country2: string;
  job_classification: string;
  address_province:  string; 
  beneficiary_firstName: string;
  beneficiary_middleName: string;
  beneficiary_lastName: string;
  beneficiary_birthday: string;
  beneficiary_contact: string;
  beneficiary_relationship: string; 
  parental_consent : string;
  istncchecked : boolean;
}

interface Step3 {
  sealProCode: string; 
  verifyingdata: boolean; 
  renewalId: number;
  encodetype: string;
  country_text: string;
  country_text2: string;
  job_classification_text: string
  province_text: string;
  responsedata: object;
  spid: string;
  upid:  string;
  pos:  string;
  usid:  string;
  secpin:  string;
  isAddAccount: boolean;
  coverages: [];
  position:string;
}

interface Step4 {
}

interface KTCreateApp extends Step1, Step2, Step3, Step4 {}

export default defineComponent({
  name: "create-account-modal",
  data() {
    return {   
        address_country: "",
        address_country2: "",
        job_classification: "",
        confirm_data: {},
        isDisabledName: false,
        coverage_desc: "",
        coverage_transtype: "",
        displayUploadParentalConsent : false,
    }
  },
  components: {
    Field,
    ErrorMessage,
    ConfirmRegisterSealProEncode  
  },
  props: { 
        allowClose: Boolean,
        inputActivationDetail: {type : Boolean, default: true},
        isAddAccount: {type: Boolean, default: false},
        sealpro_encode_modal: {type: String},
        spcode: {type: String, default: ""},
        allowChangeName: {type: Boolean, default: true},
        firstCoverage: {type: Object, default: {}},
        slotdata: Object,
        isRenewal: {type: Boolean, default: false}
  },
  setup(props, context:any) {

    const _stepperObj = ref<StepperComponent | null>(null);
    const createAccountRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);

    const countries = ref([]) as any; 
    const cities = ref([]) as any; 
    const currentUser = getCurrentUser();
    const isDisabledName = !props.allowChangeName;
    const sealProMaxCoverage = ref(0);
    const activeCoverage = ref([]) as any; 
    const regcodeCoverages = ref([]) as any;  

    const totalcoverageselected = ref(0);
    const isCoverageSelectable = ref(true);
    const job_classification_options_list = ref([]) as any; 

    const isCoverageInfoTabOpen = ref(props.isAddAccount || props.firstCoverage.coverage_transtype === 'IMMEDIATE'  || props.firstCoverage.coverage_transtype === 'RENEW')
     
    const getJobClassifications =  async () => { 
      job_classification_options_list.value = [];
        const response = await getjobclassifications(); 
        if(response.data !== undefined && response.data.list !== undefined) {
          job_classification_options_list.value = response.data.list; 
        }
    };
    
    const httpGetCountries =  async () => { 
        countries.value = [];
        const response = await getcountries(); 
        if(response.data !== undefined && response.data.countries !== undefined) {
          countries.value = response.data.countries; 
        }
       // formData.value.insured_email = 'jonzcarzon@gmail.comfa';

        
        
        if(props.firstCoverage !== undefined && props.firstCoverage.coverage_transtype === 'RENEW') {  
          
 

        }

    }; 


    const getCities = async(country_id) => {  
        cities.value = [];
        const response = await getcities(country_id); 
        if(response.data !== undefined && response.data.cities !== undefined) {
            cities.value = response.data.cities; 
        }
    };

    const getOviCoverageInfo = async() =>{
      if(props.isAddAccount) { 
          const response = await getOVICoverageInfo(); 
          sealProMaxCoverage.value = response.data.sealProMaxCoverage; 
          activeCoverage.value = response.data.activeCoverage;
      }
    };



    const getCodeCoverages = async() =>{ 
        regcodeCoverages.value = []; 
        totalcoverageselected.value = 0;
        isCoverageSelectable.value = true;

        if(props.isAddAccount) { 
            let d = {
              u: formData.value.usid,
              p: formData.value.secpin
            };
            const response = await getRegcodesCoverages(d); 
            if(response.data !== undefined && response.data !== null && response.data.coverages != undefined && response.data.coverages.length > 0) { 
              regcodeCoverages.value = response.data.coverages;  
            }

            

            let generatedCoverage: number = sealProMaxCoverage.value;
            if(regcodeCoverages.value.length > 0) { 

                let hasRepatOrEr = false;
                for(var i = 0; i < activeCoverage.value.length; i++) { 
                    if(activeCoverage.value[i].packageType !== undefined ) {
                        var activeCoveragePackageType  = activeCoverage.value[i].packageType;
                        activeCoveragePackageType = activeCoveragePackageType.toLowerCase();
                        if(activeCoveragePackageType === "be" || activeCoveragePackageType == "rp" || activeCoveragePackageType === "pe"){
                          hasRepatOrEr = true;
                        }
                    } 
                }

                for(var i = 0; i < regcodeCoverages.value.length; i++) { 
                    generatedCoverage = generatedCoverage + regcodeCoverages.value[i].amount; 
                }
                if(generatedCoverage <= 3000000){
                  isCoverageSelectable.value = false;
                  for(var i = 0; i < regcodeCoverages.value.length; i++) { 
                    regcodeCoverages.value[i].sts = true;
                    const  regcodeCoveragesCoverageType = regcodeCoverages.value[i].coverage_type;
                    if(hasRepatOrEr && (regcodeCoveragesCoverageType === "be" || regcodeCoveragesCoverageType == "rp" || regcodeCoveragesCoverageType === "pe")){
                       regcodeCoverages.value[i].sts = false;
                    }
                  }
                  selectCoverage(0);
                } 
            }
        } else {
          if(props.firstCoverage.coverage_transtype === 'IMMEDIATE') { 
            const response = await getImmediateCoverages(formData.value); 
            if(response.data !== undefined && response.data !== null && response.data.coverages != undefined && response.data.coverages.length > 0) { 
              regcodeCoverages.value = response.data.coverages;  
            } 
            sealProMaxCoverage.value = response.data.sealProMaxCoverage; 
            activeCoverage.value = response.data.activeCoverage; 
          }

          if( props.firstCoverage.coverage_transtype === 'RENEW') { 
            const response = await getrenewalavailablecoverages(formData.value); 
            if(response.data !== undefined && response.data !== null && response.data.coverages != undefined && response.data.coverages.length > 0) { 
              regcodeCoverages.value = response.data.coverages;  
            } 
            sealProMaxCoverage.value = response.data.sealProMaxCoverage; 
            activeCoverage.value = response.data.activeCoverage; 
          }


        }
  

    };

    const selectCoverage = async(id) =>{

      if(isCoverageSelectable.value) { 
          let sts = regcodeCoverages.value[id].sts;
          if(sts === undefined) {
            sts = true;
          }else {
            sts = !sts;
          } 
          regcodeCoverages.value[id].sts = sts;
      }

      totalcoverageselected.value = 0;

      for(var i = 0; i < regcodeCoverages.value.length; i++) {
        if(regcodeCoverages.value[i].sts) {
          totalcoverageselected.value = totalcoverageselected.value + regcodeCoverages.value[i].amount;
        }
      } 
    };
 


    const formData = ref<KTCreateApp>({   
      isAddAccount: props.isAddAccount ,
      renewalId: 0,
      verifyingdata: false,
      encodetype: "", 
      country_text: "",
      country_text2: "",
      job_classification_text: "",
      province_text: "",
      responsedata: {},
      insured_firstName: !props.allowChangeName ? currentUser.fname  : "",
      insured_middleName: !props.allowChangeName ? currentUser.mname  : "",
      insured_lastName: !props.allowChangeName ? currentUser.lname  : "",
      insured_birthday: !props.allowChangeName ? (currentUser.bday.substring(0,4) + "-" + currentUser.bday.substring(5,7) + "-" + currentUser.bday.substring(8,10) )  : "",
      insured_mobileNumber: props.isAddAccount ? currentUser.mobile  : "",
      insured_email: props.isAddAccount ? currentUser.email  : "",
      address_blockAndStreet: (props.firstCoverage !== undefined && props.firstCoverage.address_blockAndStreet !== undefined ? props.firstCoverage.address_blockAndStreet:  ""),
      address_nameOfBuilding:   (props.firstCoverage !== undefined && props.firstCoverage.address_nameOfBuilding !== undefined ? props.firstCoverage.address_nameOfBuilding:  ""),
      address_unitNumber: (props.firstCoverage !== undefined && props.firstCoverage.address_unitNumber !== undefined ? props.firstCoverage.address_unitNumber:  ""),
      address_city:  (props.firstCoverage !== undefined && props.firstCoverage.address_city !== undefined ? props.firstCoverage.address_city:  ""),
      address_postalCode:  (props.firstCoverage !== undefined && props.firstCoverage.address_postalCode !== undefined ? props.firstCoverage.address_postalCode:  ""),
      address_country: "",
      address_country2: "",
      job_classification: "",
      address_province: "",
      beneficiary_firstName:  (props.firstCoverage !== undefined && props.firstCoverage.beneficiary_firstName !== undefined ? props.firstCoverage.beneficiary_firstName:  ""),
      beneficiary_middleName: (props.firstCoverage !== undefined && props.firstCoverage.beneficiary_middleName !== undefined ? props.firstCoverage.beneficiary_middleName:  ""),
      beneficiary_lastName: (props.firstCoverage !== undefined && props.firstCoverage.beneficiary_lastName !== undefined ? props.firstCoverage.beneficiary_lastName:  ""),
      beneficiary_birthday: "",
      beneficiary_contact:  (props.firstCoverage !== undefined && props.firstCoverage.beneficiary_contact !== undefined ? props.firstCoverage.beneficiary_contact:  ""),
      beneficiary_relationship: (props.firstCoverage !== undefined && props.firstCoverage.beneficiary_relationship !== undefined ? props.firstCoverage.beneficiary_relationship:  ""),
      parental_consent : "",

      /*sealpro activation fields*/
      sealProCode: "" || props.spcode, 

      /*regmultiple fields*/
      spid:  (props.slotdata !== undefined && props.slotdata.spid !== undefined) ? props.slotdata.spid : "",
      upid: (props.slotdata !== undefined && props.slotdata.upid !== undefined) ? props.slotdata.upid : "",
      position: (props.slotdata !== undefined && props.slotdata.position !== undefined) ? props.slotdata.position : "",
      pos: "",
      usid: "",
      secpin: "",
      coverages: [],

      istncchecked : false
    });

    
    if(props.firstCoverage !== undefined && props.firstCoverage.coverage_transtype === 'RENEW') {  
      formData.value.insured_email = props.firstCoverage.insured_email,
      formData.value.insured_mobileNumber=  props.firstCoverage.insured_mobileNumber  ;     

    }
 
  const isDisabledUPID = props.slotdata !== undefined && props.slotdata.upid !== undefined;

    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        createAccountRef.value as HTMLElement
      );   
       
      if(props.slotdata !== undefined && props.slotdata.upid !== undefined) {
        
          formData.value.upid = props.slotdata.upid;
          formData.value.spid = props.slotdata.spid;
          formData.value.pos = props.slotdata.position;
          formData.value.position = props.slotdata.position;
          //isDisabledUPID = true;
      }


      httpGetCountries();
      getJobClassifications();
      getOviCoverageInfo();
    });

    const createAppSchema = [
      Yup.object({}),  
      
     /* Yup.object({
        insured_firstName: Yup.string()
          .required()
          .max(50)
          .label("Insured First Name"), 
        insured_lastName: Yup.string()
          .required()
          .max(50)
          .label("Insured Last Name"),
        insured_birthday: Yup.string()
          .required()
          .label("Insured Birth Date"),
        username: Yup.string()
          .required()
          .label("Your Account Username"),
        password: Yup.string()
          .required()
          .label("Your Account Password"),
        cpassword: Yup.string()
          .required()
          .label("Your Account Password Confirmation"), 
        sponsorid: Yup.string()
          .required()
          .max(20)
          .label("Sponsor User ID"),
        uplineid: Yup.string()
          .required()
          .max(20)
          .label("Upline User ID"),
        position: Yup.string()
          .required()
          .label("Position")
      }), 

      Yup.object({  
        insured_mobileNumber: Yup.string()
          .required()
          .max(20)
          .label("Insured Mobile Number"),
        insured_email: Yup.string()
          .required()
          .email()
          .max(50)
          .label("Insured Email"),
          
        beneficiary_firstName: Yup.string()
          .required()
          .max(50)
          .label("Beneficiary First Name"),
        beneficiary_lastName: Yup.string()
          .required()
          .max(50)
          .label("Beneficiary Last Name"),
        beneficiary_birthday: Yup.string()
          .required()
          .label("Beneficiary Birth Date"),
        beneficiary_relationship: Yup.string()
          .required()
          .label("Beneficiary Relationship"),
        beneficiary_contact: Yup.string()
          .required()
          .max(20)
          .label("Beneficiary Contact#"),
        address_blockAndStreet: Yup.string()
          .required()
          .max(50)
          .label("Your Block and Street address"),
        address_country: Yup.string()
          .required()
          .label("Your Address Country"),
        address_province: Yup.string()
          .required()
          .label("Your Address Province"),
        address_postalCode:  Yup.string()
          .required()
          .label("Your Address Postal Code"),

      }),

      Yup.object({ 
        userid: Yup.string()
          .required()
          .max(20)
          .label("Activation User ID"),
        securitypin: Yup.string()
          .required()
          .max(20)
          .label("Activation PIN") 


      })  */
    ];

    // extracts the individual step schema
    const currentSchema = computed(() => {
      return createAppSchema[currentStepIndex.value];
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    
    const selectedCoverages = computed(() => {
      if (!regcodeCoverages.value) {
        return;
      }

      return regcodeCoverages.value;
    });

    

    const { resetForm, handleSubmit, setFieldValue } = useForm<Step1 | Step2 | Step3 | Step4>({
      validationSchema: currentSchema
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };

   

    const handleStep = handleSubmit(values => {
      
      for (const item in values) {
        // eslint-disable-next-line no-prototype-builtins
        if (values.hasOwnProperty(item)) {
          if (values[item]) {
            formData.value[item] = values[item];
          }
        }
      }

      currentStepIndex.value++;
  
 

      if (!_stepperObj.value) {
        return;
      }
      if(!totalSteps.value) {
        return;
      }
  
  
      if(isCoverageInfoTabOpen && currentStepIndex.value === (totalSteps.value - 3)){ 
        getCodeCoverages();
      }


      if(currentStepIndex.value === (totalSteps.value - 2)){ 
        validateForm(formData.value);
      }

      _stepperObj.value.goNext();
    });


    
const validateForm = async (data: any) => {  
     

      formData.value.verifyingdata = false; 
      formData.value.country_text = countries.value.find( e => {
          if(e.country_id == formData.value.address_country) {
           return   e.name;
          }
      }).name;


      formData.value.country_text2 = countries.value.find( e => {
          if(e.country_id == formData.value.address_country2) {
           return   e.name;
          }
      }).name;

      formData.value.job_classification_text = job_classification_options_list.value.find( e => {
          var jct_name = "";
          if(e.job_classification_id == formData.value.job_classification) {
             jct_name = e.name;
          }
          return jct_name;
      }).name;
 
      
      formData.value.province_text = cities.value.find( e => {
          if(e.zone_id == formData.value.address_province) {
           return   e.name;
          }
      }).name;
  
      
      formData.value.coverages  = selectedCoverages.value;
      if(props.isAddAccount) {  
            formData.value.verifyingdata = true;
            const response: any =   await validateMultiple(data);
            formData.value.verifyingdata = false;
            if(response.data !== undefined && response.data.result === "FAILED") {  
                Swal.fire({
                  title: "",
                  text: response.data.message,
                  icon: "error",
                  confirmButtonClass: "btn btn-secondary"
                });  
                if (!_stepperObj.value) {
                    return;
                }
                currentStepIndex.value = 0;
                _stepperObj.value.goFirst();
                return;
            }
            
            if(response.data !== undefined && response.data.result === "SUCCESS") {
                formData.value.responsedata = response.data;

            }
      
      }

      if(props.isRenewal) {
        formData.value.renewalId = props.firstCoverage.recid;
      }
    };

    const formSubmit = async () => {
      
      let response: any;
      formData.value.coverages  = selectedCoverages.value;
      formData.value.encodetype =  (props.firstCoverage !== undefined &&  props.firstCoverage.coverage_transtype !== undefined ? props.firstCoverage.coverage_transtype : "");
     
     
      if(props.isRenewal) {
        formData.value.renewalId = props.firstCoverage.recid;
      }

      if(props.isAddAccount) {
         //register multiple controller 
        response =  await saveRegMultiple(formData.value);
      } else { 
          //save pro controller
          response =   await save_sealproform(formData.value);
      }
       
      

      if(response.data !== undefined && response.data.result === "FAILED") {  
          Swal.fire({
            title: "",
            text: response.data.message || response.data.requiredFields,
            icon: "error",
            confirmButtonClass: "btn btn-secondary"
          });  
           if (!_stepperObj.value) {
              return;
           }
           currentStepIndex.value = 0;
          _stepperObj.value.goFirst();
          return;
      }  
      
      if(response.data !== undefined && response.data.result === "SUCCESS") {
              Swal.fire({
                text: response.data.message,
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary"
                }
              }).then(() => {
                window.location.reload();
              });
      }

      
 
    };

    resetForm({
      values: {
        ...formData.value
      }
    });

    return {
      createAccountRef,
      totalSteps,
      previousStep,
      handleStep,
      formSubmit,
      currentStepIndex,
      formData,  
      countries,
      job_classification_options_list,
      getCities,
      cities,
      isDisabledName,
      sealProMaxCoverage,
      activeCoverage,
      getCodeCoverages,
      regcodeCoverages,
      selectCoverage, 
      totalcoverageselected,
      isCoverageInfoTabOpen,
      isDisabledUPID,
      hasOVIPackage : false
    };
  },
  methods: { 
      async logOut(){
        localStorage.clear();
        this.$router.push('/dashboard') ;
      },
      async blurBirthdate(e, otherArgument){ 
        const elementValue = e.target.value;

        let params = {
          insured_birthdate : elementValue.toString()
        }

        const response = await getoviage(params);

        if(response.data.oviAge < 18){
          this.displayUploadParentalConsent = true;
        } else {
          this.displayUploadParentalConsent = false;
        }
    },
    upload(event){
        var self = this;
        let file = event.target.files[0];
        let reader = new FileReader();

        reader.onloadend = function() {
          self.formData.parental_consent = reader.result as string;
        }
        reader.readAsDataURL(file);

    },
    async checkIfHasOVIPackage(){
      const response = await hasOVIPackage();
      console.log("hasOVIPackage");
      console.log(response.data.hasOVIPackage);

      this.hasOVIPackage = response.data.hasOVIPackage;
    },
    async checkBoxValidator(event){
        if (event.target.checked) {
          this.formData.istncchecked = true;
        } else {
          this.formData.istncchecked = false;
        }

        console.log("this.formData.istncchecked : " + this.formData.istncchecked);
      },
  },
  mounted() { 
    //this.getcountries(); 
   // this.httpGetCountries();
   this.coverage_desc =  this.firstCoverage.coverage_desc;
   this.coverage_transtype = this.firstCoverage.coverage_transtype + " ACTIVATION COVERAGE";
   this.checkIfHasOVIPackage();

  },
});
